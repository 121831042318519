
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import LessonList from '@/components/common/LessonList.vue';
import { courseDetailsMixin } from '@/mixins/courseDetailsMixin';
import CourseDetailCard from '@/components/common/CourseDetailsCard.vue';
import StartStudyModal from '@/components/student/StartStudyModal.vue';

export default defineComponent({
  components: { StartStudyModal, LessonList, CourseDetailCard },
  mixins: [courseDetailsMixin],
  computed: {
    ...mapState({ courses: 'courses' })
  }
});
