
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    id: {
      type: String, default: 'Modal'
    },
    showCancel: {
      type: Boolean, default: true
    }
  },
  methods: {
    closeModal () {
      (this.$refs.closeButton as HTMLButtonElement).click();
    },
    openModal () {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById(`openButton${this.id}`)?.click();
    }
  }
});
