
import { defineComponent, PropType } from 'vue';
import { Lesson } from '@/models';
import Modal from '@/components/common/Modal.vue';

export default defineComponent({
  name: 'StartStudyModal',
  components: { Modal },
  props: {
    lessons: {
      type: Object as PropType<Lesson[]>
    }
  },
  data () {
    return {
      checked: [] as boolean[]
    };
  },
  methods: {
    startStudy () {
      if (this.checked.length === 0) { return; }
      const checkedLessons = Object.entries(this.checked).filter(e => e[1]).map(e => e[0]);
      this.$router.push({ name: 'Lernen', params: { lessonIds: checkedLessons } });
    }
  }
});
