
import NoContent from '@/components/common/NoContent.vue';
import { Lesson } from '@/models';
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import { Statistics } from '@/models/Statistics';

export default defineComponent({
  name: 'LessonList',
  components: { NoContent },
  data () {
    return {
      lessonProgress: 100 as number,
      courseStats: {} as Statistics,
      editLessons: {
        editable: false,
        lessonId: -1
      }

    };
  },
  props: {
    lessons: {
      type: Object as PropType<Lesson[]>
    },
    loading: {
      type: Boolean, default: false
    },
    courseId: {
      type: Number,
      required: false
    }
  },
  emits: ['lessonDeleted'],
  methods: {
    async deleteLesson (lesson: Lesson) {
      const response = await confirmDelete(this.$t("messages.Delete?"), ` ${this.$t("messages.del lesson?")} (${lesson.name})`);
      if (response.isDismissed) {
        return;
      }
      await Lesson.delete(lesson.id);
      this.$emit('lessonDeleted');
    },
    async changeVisibility (lesson: Lesson) {
      lesson.visibility = !lesson.visibility;
      await Lesson.changeLessonVisibility(lesson);
    },
    roundProgress (progress: number) {
      return progress.toFixed(2);
    },
    async getStatistics () {
      if (this.courseId) { return await Lesson.getStatistics(this.courseId); }
    },
    async updateLesson (lesson: Lesson) {
      await Lesson.update(lesson);
      this.changeEditLessons(-1);
    },
    getLessonProgress (index: number) {
      return this.courseStats.lessons[index].progress;
    },
    getLessonStyle (progress: number) {
      if (progress > 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-success';
      } else if (progress > 30 && progress <= 80) {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-warning';
      } else {
        return 'progress-bar progress-bar-striped progress-bar-animated bg-danger';
      }
    },
    changeEditLessons (lessonId: number) {
      this.editLessons.editable = !this.editLessons.editable;
      this.editLessons.lessonId = lessonId;
    }
  },
  async created () {
    try {
      if (this.user.type === 'student' && this.courseId) {
        this.courseStats = await Lesson.getStatistics(this.courseId);
      }
    } catch (e) {
      await showError(Lesson.STATS_ERROR);
    }
  },
  computed: {
    ...mapState({ user: 'user' })
  }
});
