import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-group align-self-center" }
const _hoisted_2 = { class: "custom-control custom-checkbox" }
const _hoisted_3 = ["onUpdate:modelValue", "id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { ref: "modal" }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.Which units learn")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lessons, (lesson) => {
          return (_openBlock(), _createElementBlock("li", {
            key: lesson.id,
            class: "list-group-item"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("input", {
                class: "custom-control-input",
                type: "checkbox",
                name: "CheckBoxInputName",
                "onUpdate:modelValue": ($event: any) => (_ctx.checked[lesson.id] = $event),
                id: 'lesson2'+lesson.id
              }, null, 8, _hoisted_3), [
                [_vModelCheckbox, _ctx.checked[lesson.id]]
              ]),
              _createElementVNode("label", {
                class: "cursor-pointer d-block custom-control-label",
                for: 'lesson2'+lesson.id
              }, _toDisplayString(lesson.name), 9, _hoisted_4)
            ])
          ]))
        }), 128))
      ])
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startStudy && _ctx.startStudy(...args))),
        type: "button",
        class: "btn btn-primary",
        "data-dismiss": "modal"
      }, _toDisplayString(_ctx.$t("messages.start training")), 1)
    ]),
    openButton: _withCtx((props) => [
      _createElementVNode("button", _mergeProps(props, {
        class: "btn btn-primary",
        disabled: _ctx.lessons?.length===0 ?? false
      }), _toDisplayString(_ctx.$t("messages.start training")), 17, _hoisted_5)
    ]),
    _: 1
  }, 512))
}